.pickup-address-table-container {
  display: inline-flexbox;
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 180px);
  max-width: calc(100vw - 130px);
  table {
    border: 2px solid red;
    table-layout: auto;
    width: 99%;
  }
  thead {
    background-color: #3182ce;
    border-radius: 5;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
  }
  tr td {
    width: 1%;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
  }
}
