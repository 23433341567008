.calculate-section {
  height: auto;
  width: calc(99vw - 1px);
  margin: 0 !important;
}
.awb-container-page {
  height: calc(100vh - 95px);
  overflow-y: scroll;
}
.tabPanel {
  width: calc(99vw - 80px);
  overflow: auto;
}
@media only screen and (max-width: 770px) {
  .tabPanel {
    width: calc(99vw - 1px);
    overflow: auto;
  }
}
.react-datepicker-wrapper {
  position: absolute;
  left: 10px;
  margin-top: 4px;
}

@media only screen and (max-width: 1000px) {
  .react-datepicker-wrapper {
    position: relative;
    left: 35vw;
  }
}
.react-datepicker-popper {
  z-index: 99;
}
.active {
  background-color: white;
  > {
    background-color: white !important;
  }
}
